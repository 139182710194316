// ------------- BORDERS -------------

.border-light {
    border-color: var(--light);
}

.border-blue {
    border-color: var(--blue) !important;
}

.border-main {
    border-color: var(--main) !important;
}

// ------------- BACKROUNDS -------------

.bg-main {
    background-color: var(--main);
    color: var(--light)
}

.bg-light {
    background-color: var(--light);
    color: black;
}

// ------------- SVGS -------------

.svg-fill-light svg{
    fill: var(--light);
} 

// ------------- TEXTS -------------

.text-black {
    color: black !important;
}

.text-light {
    color: var(--light);
}

// ------------- FILLS -------------

.fill-black svg {
    fill: black !important;
}

.fill-light svg {
    fill: var(--light) !important;
}

.fill-blue svg {
    fill: var(--blue)
}

// ------------- LINKS -------------

.link-text-black:link,  .link-text-black:visited {
    color: black !important;
}

.link-text-dark:link,  .link-text-dark:visited {
    color: var(--dark-blue) !important;
}

.hover-text-white:hover, .hover-text-white:active {
    color: var(--light) !important;
}

// ------------- TEXT-SHADOWS -------------

.text-black-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

// ------------- BOX-SHADOWS -------------

.box-shadow-simple {
    box-shadow: 10px -10px 0 var(--blue);
}

// ------------- GRADIENTS -------------

.gradient-purple {
    background: linear-gradient(to right, var(--purple), var(--dark-purple));
}