.vivi-wrap {
    background-color: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: fixed;
    z-index: 0;
}

.tortoiseshell {
    width: calc(100vw / 7);
    height: calc(100vw / 7);
    background-color: var(--blue);
    border-radius: 0%;
    margin: 0;
}

.disapear {
    opacity: 0;
    animation: disapear ease-in-out 1s;
}

@keyframes disapear {
    0% {opacity: 100%;}
}