.hover-up:hover {
    box-shadow: 3px 3px 2px var(--blue) inset, -3px -3px 2px var(--light-blue) inset;
}

link {
    background: 
      linear-gradient(to right, rgba(100, 200, 200, 1), rgba(100, 200, 200, 1)),
      linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 180, 1), rgba(0, 100, 200, 1));
    background-size: 100% 0.1em, 0 0.1em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
  }
  
  link:hover,
  link:focus {
    background-size: 0 0.1em, 100% 0.1em;
  }

  .fade {
    opacity: 0;
    animation: fade 0.1s ease-in-out;
  }

  @keyframes fade {
    0% {opacity: 100%;}

  }

  .fade-out {
    opacity: 100;
    animation: fadeOut 1s ease-in-out;
  }


  @keyframes fadeOut {
    0% {opacity: 0%;}
  }

  .translate-x {
    transform: translateX(200%);
    animation: translateX 1s ease-in-out;
  }

  @keyframes translateX {
    0% {transform: translateX(0%);}
  }

  .translate-y {
    transform: translateY(-200%);
    animation: translateY 1s ease-in-out;
  }

  @keyframes translateY {
    0% {transform: translateY(0%);}
  }

 .slide-in-y-down {
  animation: 0.5s slideInYDown ease-in-out;
 }

 @keyframes slideInYDown {
    0% {transform: translateY(200%);}
 }

 .slide-in-y-up {
  animation: 0.5s slideInYUp ease-in-out;
 }

 @keyframes slideInYUp {
    0% {transform: translateY(-200%);}
 }


.float {
  animation: floating 3s ease-in-out infinite;
}

 @keyframes floating {
  0% {transform: translateY(-5px);}
  50% {transform: translateY(5px);}
  100% {transform: translateY(-5px);}
}

.floating-neon {
  animation: floating 3s ease-in-out infinite;
}

@keyframes floatingNeon {
  0% {transform: translateY(-5px); opacity: 70%;}
  50% {transform: translateY(5px); opacity: 50%;}
  100% {transform: translateY(-5px); opacity: 70%;}
}

.img-cover {
  background-color: rgba(127, 255, 212, 0.277);
}

.hover-svg-breath:hover svg {
  animation: zoomSvg 2s ease-in-out infinite !important;
}

@keyframes zoomSvg {
  0% { transform: scale(1);}
  25% {transform: scale(1.2);}
  75% {transform: scale(1.2);}
  100% { transform: scale(1);}
}

.transition-1 {
  transition: ease-in-out 0.1s;
}

.transition-2 {
  transition: ease-in-out 0.2s;
}

.transition-3 {
  transition: ease-in-out 0.3s;
}

.open {
  animation: openDiv 1s ease-in-out;
  opacity: 1;
  overflow: hidden;
  // border-left: 1px solid orange;
  padding: 10px;
  background: linear-gradient(to left, orange, orange);
  background-position: 0% 0%;
  background-size: 1px 100%;
  background-repeat:no-repeat;
}

@keyframes openDiv {
  0% { opacity: 0; background-size: 1px 0%;}
}

.grow-h {
  animation: growH 0.5s ease-in-out;
}

.grow-h-2 {
  animation: growH2 0.5s ease-in-out;
}

@keyframes growH {
  0% {opacity: 0;}
}

@keyframes growH2 {
  0% {opacity: 0;}
}