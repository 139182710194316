@media (max-width: 600px) {
    .sm-text-center {
        text-align: center
    }

    .sm-justify-center {
        justify-content: center;
    }

}

@media (max-width: 1279px) and (min-width: 601px) {
    .md-text-center {
        text-align: center
    }

    .md-flex {
        display: flex;
    }

    .md-center {
        align-items: center;
    }

    .md-justify-center {
        justify-content: center;
    }
}

@media (min-width: 1280px) {
    .lg-text-center {
        text-align: center
    }

    .lg-flex {
        display: flex;
    }

    .lg-center {
        align-items: center;
    }

    .lg-justify-center {
        justify-content: center;
    }
}

// ------------- DISPLAYS -------------

.flex {
    display: flex;
}

.block {
    display: block;
}

// ------------- POSITIONS -------------

.fix {
    position:fixed;
}

.top-0 {
    top: 0px;
}

.top-2 {
    top: 20px;
}

.top-3 {
    top: 30px;
}

.top-4 {
    top: 40px;
}

.bottom-0 {
    bottom: 0px;
}

.bottom-2 {
    bottom: 20px;
}

.right-0 {
    right: 0px;
}

.right-2 {
    right: 20px;
}

.right-3 {
    right: 30px;
}

.left-0 {
    left: 0px;
}

.left-2 {
    left: 20px;
}

.item-center {
    align-items: center;
}

.center-fix {
    left: 50%;
    transform: translate(-50%, 0);
}

// ------------- JUSTIFY -------------

.justify-center {
    justify-content: center;
}

.justify-right {
    justify-content: right;
}

.flex-end {
    justify-content: flex-end;
}

.space-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

// ------------- TEXT-POSITIONS -------------

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

// ------------- Z-INDEX -------------

.z-3 {
    z-index: 300;
}

.z-4 {
    z-index: 400;
}

.z-5 {
    z-index: 500;
}

.flex-wrap {
    flex-wrap: wrap;
}

// ------------- OVERFLOWS -------------

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}