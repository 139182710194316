@media (max-width: 600px) {
    .sm-small-picture {
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .sm-medium-picture {
        width: 200px;
        height: 200px;
        overflow: hidden;
    }

    .sm-large-picture {
        width: 300px;
        height: 300px;
        overflow: hidden;
    }

    .sm-small-picture img,
    .sm-medium-picture img,
    .sm-large-picture img {
        width: 100%;
    }
}

@media (max-width: 1279px) and (min-width: 601px) {
    .md-small-picture {
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .sm-medium-picture {
        width: 200px;
        height: 200px;
        overflow: hidden;
    }

    .md-small-picture img,
    .md-medium-picture img {
        width: 100%;
    }
}

.button {
    border-radius: 3px;
    background-color: transparent;
    padding: 10px;
    width: fit-content;
    transition: 0.2s ease-in-out;
    color: var(--light);
    border: none;
    margin: 0 10px;
    background-color: rgba(255, 255, 255, 0.1);
}

.button:hover {
    background-color: var(--light);
    color: var(--dark);
}

.button:focus {
    outline: none;
}

.button-dark {
    border-radius: 3px;
    background-color: transparent;
    padding: 10px;
    width: fit-content;
    transition: 0.2s ease-in-out;
    color: black;
    border: 1px solid black;
    margin: 0 10px;
    background-color: rgba(255, 255, 255, 0.1);
}

.button-dark:hover {
    background-color: var(--dark);
    color: var(--light);
}

.button-dark:focus {
    outline: none;
}