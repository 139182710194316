.input-field {
    width: 100%;
    padding: 10px;
    border: 2px solid var(--main);
    border-radius: 3px;
    transition: 0.5s ease-in-out;
    background-color: var(--light);
}

.input-field:focus {
    outline: none;
    border: 2px solid var(--purple);
    border-radius: 3px;
}

.input-field-admin {
    padding: 8px;
    background-color: transparent;
    border: 1px solid var(--light);
    transition: 0.5s ease-in-out;
    width: 30%;
    color: var(--light);
}

.input-field-admin:focus {
    outline: none;
}

.sumbit-btn-light {
    width: 100%;
    padding: 10px;
    color: var(--purple);
    border: 2px solid var(--purple);
    background-color: transparent;
    transition: 0.5s ease-in-out;
    border-radius: 3px;
}

.sumbit-btn-light:hover {
    outline: none;
    background-color: var(--purple);
    color: var(--light);
}