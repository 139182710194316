
.law-fields-title-container {
  h2 {
    text-align: center;
  }
}

.expert-center-picture {
    width: calc(100%);
    padding: 50px 0;
    transition: 0.5s ease-in-out;
}

.expert-text {
    font-size:medium;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80vw;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: justify;
    max-height: 70vh;
    overflow-y: auto;
    padding: 0 100px;
  }

  .expert-container {
    min-height: 65vh;
    width: 50%;
    position: relative;
  }

  .lawfields-ul {
    list-style-position: inside;
  }

.expert-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;
    background-color: transparent;
  }

  .col-left {
    position: fixed;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(10vw + 30px);
    top: 0;
    left: 0;
  }

  .col-right {
    position: fixed;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(10vw + 30px);
    top: 0;
    right: 0;
  }

  .expert-button-img {
    transition: 0.2s ease-in-out;
    max-width: 10vw;
  }

  .expert-button {
    padding : 15px;
    background-color: transparent;
    border: none;
    transition: 0.5 ease-in-out;
  }

  .expert-button:focus {
    outline: none;
  }

  .expert-button:hover img{
    transform: scale(0.9);
  }