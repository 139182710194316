.music-modale {
    position: fixed;
    z-index: 3000;
    width: 100vw;
    height: 100vh;
    background-color: var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
}

.music-toggle {
    color: var(--light);
    background-color: transparent;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid var(--light);
    transition: 0.5s ease-in-out;
}

.music-toggle:hover {
    background-color: var(--light);
    color: var(--main);
    border: none;
}

.music-toggle:focus {
    outline: none;
}

.sound-toggler {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 10px;
    z-index: 300;
}

.sound-toggler svg {
    fill: var(--light);
    height: 30px;
    width: 30px;
    transition: 0.5s ease-in-out;
}

.audio-tag{
    display:none;
}

.mouse {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    z-index: 20;
    position: absolute;
}

.call-to-click {
    position: fixed;
    transition: 0.3s ease-in-out;
    background-color: var(--blue);
    width: 200px;
    height: 200px;
    top: calc(100% - 100px) !important;
    left: calc(50% - 100px) !important;
    box-shadow: 0 0 200px var(--blue);
}

.show-sound-toggler {
    animation: popUp 0.7s ease-in-out;
}

@keyframes popUp {
    0% { border-radius: 50%; outline: 1px solid white; outline-offset: 0px;  }
    40% { border-radius: 50%; outline: 1px solid white; outline-offset: 200px; }
    60% { border-radius: 50%; outline: 1px solid white; outline-offset: 200px;}
    100% { border-radius: 50%; outline: 1px solid white; outline-offset: 0px; }
}

.CGU {
    padding: 10px;
    color: var(--light);
    position: fixed;
    bottom: 0;
    right: 0;
}

.CGU:hover {
    color: var(--blue);
}

blue {
    color: var(--dark-purple);
    text-decoration: underline;
}

.inset {
    list-style: none;
    padding: 0 10px;
}

.cgu-a {
    text-decoration: none;
    color: black;
}

.cgu-a:hover {
    color: var(--blue);
}

.pointer {
    cursor: pointer;
}