@media (max-width: 600px) {
    .sm-nav-link {
        padding: 5vh 0 !important;
        width: 10vw !important;
        margin: 0 5px !important;
    }
}

@media (max-width: 1279px) and (min-width: 601px) { 
    .md-nav-link {
        padding: 5vh 0 !important;
    }
}

@media (min-width: 1280px) {
    .lg-nav-link-invert:link, .lg-nav-link-invert:visited {
        background: linear-gradient(to bottom, var(--blue), var(--dark-blue));
        background-position: 0% 0%;
        background-size: 100% 0;
        background-repeat:no-repeat;
    }
}

.ban-div {
    text-align: left;
}

.email-link {
    color: var(--light);
    transition: ease-in-out 0.1s;
}

.email-link:hover {
    color: var(--gray);
}

.nav-link:link, .nav-link:visited {
    display: block;
    padding: 50px 0;
    background: linear-gradient(to top, var(--light-blue), var(--blue));
    color: var(--light);
    transition: ease-in-out 0.1s;
    text-decoration: none;
    width: 25vw;
    text-align: center;

    background-position: 0% 50%;
    background-size: 100% 0;
    background-repeat:no-repeat;
}

.nav-link-purple:link, .nav-link-purple:visited {
    display: block;
    padding: 50px 0;
    background: linear-gradient(to top, var(--purple), var(--purple));
    color: var(--light);
    transition: ease-in-out 0.1s;
    text-decoration: none;
    width: 25vw;
    text-align: center;

    background-position: 0% 50%;
    background-size: 100% 0;
    background-repeat:no-repeat;
}

.nav-link-invert:link, .nav-link-invert:visited {
    background: linear-gradient(to bottom, var(--light-blue), var(--blue), var(--dark-blue));
    background-position: 0% 0%;
    background-size: 100% 0;
    background-repeat:no-repeat;
}

.nav-link:active, .nav-link:hover {
    // background-color: var(--blue);
    background-size: 100% 100% ; 
    color: var(--light);
}

.nav-link-purple:active, .nav-link-purple:hover {
    // background-color: var(--blue);
    background-size: 100% 100% ; 
    color: var(--light);
}

.nav-ban {
    background-color: rgba(128, 128, 128, 0.177);
}

.nav-spacing {
    padding: 50px 0;
}

.nav-link-small:link, .nav-link-small:visited {
    background: linear-gradient(to left, var(--blue),  var(--blue), var(--blue));
    transition: ease-in-out 0.3s;
    text-decoration: none;
    text-align: center;
    width: fit-content;
    background-position: 50% 100%;
    background-size: 0% 2px;
    background-repeat:no-repeat;
}

.nav-link-small:active, .nav-link-small:hover {
    // background-color: var(--blue);
    background-size: 100% 2px ; 
}
// .square-nav {
//     width: 100%;
//     position: fixed;
//     top: 200px;
//     right: 50px;
//     width: calc(50vw + 4 * 20px);
// }

.square-nav a {
    margin: 20px;
}

.side-nav {
    width: 25vw;
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    transition: 0.5s ease-in-out;
}

.li-link, .li-link svg {
    transition: 0.2s ease-in-out;
}

.li-link:hover svg, .li-link:active svg {
    fill: var(--blue) !important;
    transform: scale(1.2);
}

.menu-burger {
    transform: rotate(180deg);
    width: 50px;
    height: 50px;
    position: fixed;
    top: 20px;
    right: 20px;
    justify-content: right;
    text-align: right;
}

.menu-bar {
    margin: 10px 0;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background-color: var(--light);
    display:block;
    background-position: 0 100%;
}

.grow1 {
    animation: grow1 5s ease-in-out infinite;
}

.grow2 {
    animation: grow2 5s ease-in-out infinite;
}


.grow3 {
    animation: grow3 5s ease-in-out infinite;
}


@keyframes grow1 {
    0% {width: 100%; background-color: var(--light-blue);}
    25% {width: 50%;}
    50% {width: 4px; background-color: #fff;}
    75% {width: 50%;}
    100% {width: 100%; background-color: var(--light-blue);}
}

@keyframes grow2 {
    0% {width: 50%;}
    25% {width: 4px; background-color: #fff;}
    50% {width: 50%}
    75% {width: 100%; background-color: var(--light-blue);}
    100% {width: 50%;}
}

@keyframes grow3 {
    0% {width: 4px; background-color: #fff}
    25% {width: 50%;}
    50% {width: 100%; background-color: var(--light-blue);}
    75% {width: 50%;}
    100% {width: 4px; background-color: #fff}
}

.grow1-purple {
    animation: grow1-purple 5s ease-in-out infinite;
}

.grow2-purple {
    animation: grow2-purple 5s ease-in-out infinite;
}


.grow3-purple {
    animation: grow3-purple 5s ease-in-out infinite;
}


@keyframes grow1-purple {
    0% {width: 100%; background-color: var(--purple);}
    25% {width: 50%;}
    50% {width: 4px; background-color: #fff;}
    75% {width: 50%;}
    100% {width: 100%; background-color: var(--purple);}
}

@keyframes grow2-purple {
    0% {width: 50%;}
    25% {width: 4px; background-color: #fff;}
    50% {width: 50%}
    75% {width: 100%; background-color: var(--purple);}
    100% {width: 50%;}
}

@keyframes grow3-purple {
    0% {width: 4px; background-color: #fff}
    25% {width: 50%;}
    50% {width: 100%; background-color: var(--purple);}
    75% {width: 50%;}
    100% {width: 4px; background-color: #fff}
}

@keyframes floating {
    0% {transform: translateY(-5px);}
    50% {transform: translateY(5px);}
    100% {transform: translateY(-5px);}
}

.drop-down-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
    height: 100vh;
    background-color: var(--light);
    transition: ease-in-out 0.5s;
    overflow: auto;
}

@media (max-width: 1280px) { 
    .collapse-menu {
        height: 0;
        animation: collapseMenu 0.5s ease-in-out;
    }
}


@keyframes collapseMenu {
    0% {height: 100%;}
}

.drop-up-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
    height: 100vh;
    background-color: var(--main);
    transition: ease-in-out 0.5s;
    overflow: auto;
}

.drop-up-toggle {
    position: fixed;
    bottom: 0;
    width: 30%;
    margin: 5px calc(70% / 2);
    animation: floatingNeon 3s infinite ease-in-out;
}

.drop-up-toggle:hover {
    animation: stayUp 25s infinite ease-in-out;
}

.drop-up-toggle svg {
    width: 5%;
    margin: 0 calc(95%/2);
}

.drop-up-text {
    text-align: center;
    width: 100%;
    font-weight: 800;
}

.drop-up-element {
    transition: 0.2s ease-in-out;
}

.collapse-down-menu {
    transform: translateY(200%);
    animation: collapseDownMenu 0.5s ease-in-out;
}

@keyframes collapseMenu {
    0% {transform: translateY(0%);}
}

@keyframes floatingNeon {
    0% {transform: translateY(-5px); opacity: 70%;}
    50% {transform: translateY(5px); opacity: 50%;}
    100% {transform: translateY(-5px); opacity: 70%;}
}

@keyframes stayUp {
    1% {transform: translateY(-5px); opacity: 70%;}
    100% {transform: translateY(-5px); opacity: 70%;}
}

// ------------ BANNER ----------

.banner {
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;  
}

.link:link, .link:visited {
    text-decoration: none;
    color: var(--light);
    transition: ease-in-out 0.2s;
}

.link:hover, .link:active {
    color: var(--blue);
}