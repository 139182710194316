.home-img {
    flex-shrink: 0;
    width: 100%;
}

.home-hero { 
    height: 50vh;
}

.home-img-frame {
    object-fit: cover;
}

#canvas {
    z-index: 10;
    border: 1px solid orange;
}

#flowmap {
    width: 100%;
    height: 100vh;
    border: 1px solid plum;
}

#flowmap img {
    display: none;
}


.cta-link:link, .cta-link:visited {
    background: linear-gradient(to left, var(--blue), var(--blue));
    color: var(--light);
    transition: ease-in-out 0.3s;
    text-decoration: none;
    text-align: center;
    width: fit-content;
    background-size: 0% 100%;
    background-repeat:no-repeat;
    animation: hilight 5s ease-in-out infinite;
}

.cta-link:active, .cta-link:hover {
    padding: 5px;
    background-size: 100% 100% ;
    animation: none;
}

@keyframes hilight {
    0% {background-position: 0% 100%;}
    40% { background-position: 0% 100%; background-size: 100% 100% ;}
    60% { background-position: 100% 0%; background-size: 100% 100% ;}
    100% { background-position: 100% 0%}
}

.hilighted-blue {
    text-decoration: underline var(--light) 2px;
    transition: 0.3s ease-in-out;
}

.hilighted-blue:hover {
    text-decoration: underline var(--blue) 2px;
}

.card-name {
    padding: 100px 0 0 100px;
}


.container {
    position: relative;
    width: 50%;
  }

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: var(--main);
  }
  
  .container:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0 100px;
  }

  .scroll {
    overflow: auto;
  }