:root {
    --main: #1A1617;
    --blue: #0077b6;
    --middle-blue: #023e8a;
    --light-blue: #0077b6;
    --dark-blue: #03045e;
    --gray: #caf0f8;
    --light-gray: #E8E9ED;
    --dark-gray: #AFB3C0;
    --light1: #FFF;
    --light2: #FFF;
    --light2-transparent: #f2efe4c1;
    --purple: #5E3880;
    --light-purple: #8855B4;
    --light-purple-transparent: rgba(136, 85, 180, 0.308);
    --dark-purple: #341F47;
}

@import './colors.scss',
'./borders.scss',
'./navbar.scss',
'./pages.scss',
'./sizes.scss',
'./display.scss',
'./decorations.scss',
'./molecules.scss',
'./effects.scss',
'./vivi-bg.scss',
'./home.scss',
'./inputs.scss',
'./routeur.scss',
'./admin.scss',
'./articles.scss',
'./appointment.scss',
'./law_fields.scss';


@font-face {
    font-family: 'Durer';
    src: url('../assets/fonts/Durer.ttf') format('truetype'),
        url('../assets/fonts/Durer.woff') format('woff');
}

@font-face {
    font-family: 'Bosch';
    src: url('../assets/fonts/Bosch.otf') format('truetype');
}

@font-face {
    font-family: 'Uni';
    src: url('../assets/fonts/UniSansThin.otf') format('truetype');
}

@font-face {
    font-family: 'Adequate';
    src: url('../assets/fonts/Adequate-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'hand-scripted';
    src: url('../assets/fonts/MrDeHaviland-Regular.ttf') format('truetype');
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;;
    scrollbar-color: red, blue;
}

html,
body {
    background-color: var(--main);
}

.bosch-font {
    font-family: Bosch;
}

.text-light {
    color: var(--light);
}

.hand-signed {
    color: var(--blue);
    font-family: 'hand-scripted';
    font-size: 1.5rem;
}

a.btnIframMl {
    bottom: 34px;
    right: 78px;
    font-size: 19px;
    font-weight: 500;
    color: var(--blue);
    text-shadow: 2px 1px 2px rgba(255, 255, 255, 0.7);
    margin: auto;
    margin-right: 0;
    display: block;
    text-align: right;
    text-decoration: none;
    line-height: 1.6;
}

a.btnIframMl span {
    font-size: 12px;
    position: relative;
    top: initial;
    line-height: 1;
    display: inline-flex;
    align-items: flex-start;
}

a.btnIframMl span::before {
    background: url(https://rdv.meetlaw.fr/ml.png) no-repeat;
    content: '';
    display: inline-block;
    width: 150px;
    height: 31px;
    position: relative;
    top: initial;
    background-size: contain;
}

a.btnIframMl:hover {
    color: var(--blue);
    opacity: .7;
}

img[alt=md-image] { width: 20%; }