@media (max-width: 600px) {
    .sm-underline-1 {
        border-bottom: solid 1px;
    }
}

@media (max-width: 1279px) and (min-width: 601px) {
    .md-underline-1 {
        border-bottom: solid 1px;
    }
}

@media (min-width: 1280px) {
    .lg-underline-1 {
        border-bottom: solid 1px;
    }
}

.border-1 {
    border: solid 1px;
}

.border-left-2 {
    border-left: solid 2px;
}

.radius-3 {
    border-radius: 3px;
}

.radius-5 {
    border-radius: 5px;
}